import React from 'react';
import { Route, Routes } from 'react-router-dom';
import {
  LecturerEarnings,
  LecturerCourses,
  LecturerCourseContent,
  LecturerMediaLibrary,
  LecturerCourse,
  LecturerSection,
  LecturerProfile,
  LecturerMediaViewer,
} from '../modules/LecturerPortal';
import LecturerLayout from '../modules/LecturerPortal/components/LecturerLayout';
import { ProtectedRoute } from '../components';
import { UserType } from '../types';
import { SearchProvider } from '../modules/SearchContext';

const LecturerRoutes = () => {
  return (
    <Routes>
      <Route
        path="/lecturer"
        element={
          <ProtectedRoute allowedRoles={[UserType.Instructor]}>
            <SearchProvider>
              <LecturerLayout />
            </SearchProvider>
          </ProtectedRoute>
        }
      >
        {/* <Route path="earnings" element={<LecturerEarnings />} /> */}
        <Route path="courses/:courseId/view/:mediaId" element={<LecturerCourseContent />} />
        <Route path="media" element={<LecturerMediaLibrary />} />
        <Route path="courses/:id" element={<LecturerCourse />} />
        <Route path="courses" element={<LecturerCourses />} />
        <Route path="courses/:courseId/section/:sectionId" element={<LecturerSection />} />
        <Route path="profile" element={<LecturerProfile />} />
        <Route path="view/:mediaId" element={<LecturerMediaViewer />} />
      </Route>
    </Routes>
  );
};
export default LecturerRoutes;
