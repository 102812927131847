import React from 'react';

const LecturerCourseSkeleton = () => {
  return (
    <div className="flex flex-col bg-white rounded shadow-sm page-card-container animate-pulse">
      <div className="flex content-center justify-between m-3 rounded-lg bg-gray-300 h-[132px] pl-8">
        <div className="self-center p-6 mb-0 bg-gray-400 w-1/2 h-8"></div>
        <div className="self-center me-8 bg-gray-400 w-36 h-14 rounded"></div>
      </div>
      <div className="flex-grow px-6 pb-6">
        <div className="mt-2 h-10 bg-gray-300 w-1/3 mb-4 rounded"></div>
        <div className="space-y-4">
          {[...Array(3)].map((_, index) => (
            <div key={index} className="border-b">
              <div className="px-6 py-4">
                <div className="h-6 bg-gray-300 w-1/3 mb-2 rounded"></div>
                <div className="h-4 bg-gray-300 w-2/3 rounded"></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LecturerCourseSkeleton;
