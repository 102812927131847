import React, { useState, useEffect } from 'react';
import { List, X } from 'react-bootstrap-icons';
import { Link, useLocation } from 'react-router-dom';
import { MenuItem, LogoComponent } from '../../../components';
import { useUserName, useUserProfileImage } from '../../../custom-hooks/useUser';
import { UserIcon, BookIcon, LogoutIcon, OverviewIcon, CourseIcon } from '../../../assets/svg-components';
import { logout } from '../../../utils/utilMethods';
import { useSearch } from '../../SearchContext';

const LecHeader = () => {
  const [show, setShow] = useState(false);
  const { pathname } = useLocation();
  const { searchQuery, setSearchQuery } = useSearch();

  useEffect(() => {
    setShow(false);
  }, [pathname]);

  const handleLogOut = async () => {
    await logout();
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };

  return (
    <div className="bg-white py-3">
      <div className="flex justify-between">
        <List size={25} color="black" className="lg:hidden self-center ms-2" onClick={() => setShow(!show)} />

        {/* <div className="w-full lg:max-w-sm md:w-1/2 mr-auto">
          <SearchBar onSearch={handleSearch} />
        </div> */}

        <div className="me-8 lg:ms-auto">
          <Link to="/lecturer/Profile" className="no-underline flex items-center">
            <img src={useUserProfileImage()} className="rounded-full me-2 4k:me-6 w-10 h-10 4k:w-14 4k:h-14" />
            <p className="font-poppins text-base 4k:text-2xl font-medium text-black mb-0">{useUserName()}</p>
          </Link>
        </div>
      </div>

      <div
        className={`fixed top-0 left-0 z-40 h-screen p-4 overflow-y-auto transition-transform ${
          show ? 'translate-x-0' : '-translate-x-full'
        } bg-white w-64 dark:bg-gray-800`}
      >
        <div className="mb-5 flex justify-between">
          <LogoComponent />
          <X size={30} color="black" className="self-center" onClick={() => setShow(!show)} />
        </div>
        <div className="py-4 overflow-y-auto">
          <Link className="no-underline" to="/lecturer/courses">
            <MenuItem title="Courses" icon={CourseIcon} active={/^\/lecturer\/courses(\/|$)/.test(pathname)} />
          </Link>
          <Link className="no-underline" to="/lecturer/media">
            <MenuItem title="Book Shelf" icon={BookIcon} active={/^\/lecturer\/(media$|view\/\w+)$/.test(pathname)} />
          </Link>
          {/* <Link className="no-underline" to="/lecturer/earnings">
            <MenuItem title="Earnings" icon={OverviewIcon} active={pathname === '/lecturer/earnings'} />
          </Link> */}
          <Link className="no-underline" to="/lecturer/profile">
            <MenuItem title="Profile" icon={UserIcon} active={pathname === '/lecturer/profile'} />
          </Link>
          <hr />
          <div onClick={handleLogOut}>
            <MenuItem active={false} title="LogOut" icon={LogoutIcon} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default LecHeader;
