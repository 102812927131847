/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import axios from '../../utils/axios';
import { useParams } from 'react-router-dom';
import { MediaViewer } from '../../components';
import { LecturerCourseProgressView } from './components';
import { Media, Section } from '../../types';
import { AxiosError } from 'axios';
import { DEFAULT_ERROR_MESSAGE } from '../../utils/constants';

const LecturerCourseContent = () => {
  const [error, setError] = useState('');
  const [mediaObject, setMediaObject] = useState<Media>();
  const [mediaLoading, setMediaLoading] = useState(false);
  const [sections, setSections] = useState<Section[]>();
  const [sectionLoading, setSectionLoading] = useState(false);
  const { mediaId, courseId } = useParams();

  const getMediaObject = async () => {
    try {
      setMediaLoading(true);
      const res = await axios.get(`media/${mediaId}`);
      setMediaObject(res.data);
      setMediaLoading(false);
    } catch (err) {
      setMediaLoading(false);
      if (err instanceof AxiosError) {
        const errorMessage: string = err.response ? err.response.data.message : DEFAULT_ERROR_MESSAGE;
        setError(errorMessage);
      } else {
        setError(err.message);
      }
    }
  };

  const getCourseObject = async () => {
    try {
      setSectionLoading(true);
      const res = await axios.get(`/instructors/courses/${courseId}/sections`);
      setSections(res.data);
      setSectionLoading(false);
    } catch (err) {
      setSectionLoading(false);
      if (err instanceof AxiosError) {
        const errorMessage: string = err.response ? err.response.data.message : DEFAULT_ERROR_MESSAGE;
        setError(errorMessage);
      } else {
        setError(err.message);
      }
    }
  };

  useEffect(() => {
    getMediaObject();
    getCourseObject();
  }, [mediaId]);
  return (
    <div className="bg-white rounded page-card-container">
      <div className="grid gap-4 lg:grid-cols-12 p-6 h-full">
        <div className="lg:col-span-8 h-full">
          {mediaLoading ? (
            <div className="w-full h-full animate-pulse bg-white flex flex-col py-4">
              <div className="flex-grow bg-gray-300 mb-4"></div>
              <div className="h-8 w-64 bg-gray-300"></div>
            </div>
          ) : (
            <MediaViewer mediaObject={mediaObject!} />
          )}
        </div>
        <div className="lg:col-span-4 flex flex-col h-full overflow-y-auto">
          <div className="p-3 mb-3 bg-white rounded shadow font-lato font-medium text-lg">
            <h1 className="font-lato font-semibold text-lg mb-0 capitalize">Course content</h1>
          </div>
          {sectionLoading ? (
            <div className="w-full h-full animate-pulse flex flex-col">
              <div className="h-20 w-full bg-gray-300 flex flex-row justify-between items-center px-8">
                <div className="bg-gray-400 h-6 w-28 rounded"></div>
                <div className="bg-gray-400 h-6 w-6 rounded"></div>
              </div>
              <div className="h-20 w-full bg-gray-300 flex flex-row justify-between items-center px-8">
                <div className="bg-gray-400 h-6 w-28 rounded"></div>
                <div className="bg-gray-400 h-6 w-6 rounded"></div>
              </div>
              <div className="h-20 w-full bg-gray-300 flex flex-row justify-between items-center px-8">
                <div className="bg-gray-400 h-6 w-28 rounded"></div>
                <div className="bg-gray-400 h-6 w-6 rounded"></div>
              </div>
            </div>
          ) : (
            <LecturerCourseProgressView sections={sections} />
          )}
        </div>
      </div>
    </div>
  );
};

export default LecturerCourseContent;
