export enum MediaType {
  VIDEO = 'Video',
  IMAGE = 'Image',
  DOCUMENT = 'Document',
}

export enum TransactionStatusType {
  SUCCESSFUL = 'Sucessful',
  FAILURE = 'Failure',
}

export const imageUrls = {
  studentsReading: 'https://storage.googleapis.com/studyitlive_website_images/Website_Images/studentsReading.png',
  BoyDesktop: 'https://storage.googleapis.com/studyitlive_website_images/Website_Images/boyDesktopCropped.png',
  BoyPack: 'https://storage.googleapis.com/studyitlive_website_images/Website_Images/BoyPack.png',
  BoyLibrary: 'https://storage.googleapis.com/studyitlive_website_images/Website_Images/boyLibrary.png',
  GirlTree: 'https://storage.googleapis.com/studyitlive_website_images/Website_Images/GirlTree.png',
  BoyLap: 'https://storage.googleapis.com/studyitlive_website_images/Website_Images/BoyLap.png',
  Frame1: 'https://storage.googleapis.com/studyitlive_website_images/Website_Images/frame1.png',
  TeacherZoom: 'https://storage.googleapis.com/studyitlive_website_images/Website_Images/teacherZoom2Cropped.png',
  TeacherBoard: 'https://storage.googleapis.com/studyitlive_website_images/Website_Images/teacherBoard2Cropped.png',
  officeDeskSetup: 'https://storage.googleapis.com/studyitlive_website_images/Website_Images/officeDeskSetup.png',
  officeMeeting: 'https://storage.googleapis.com/studyitlive_website_images/Website_Images/officeMeeting.png',
  deskSetup: 'https://storage.googleapis.com/studyitlive_website_images/Website_Images/deskSetup.png',
  User1: 'https://storage.googleapis.com/studyitlive_website_images/Website_Images/user1.webp',
  User2: 'https://storage.googleapis.com/studyitlive_website_images/Website_Images/user2.webp',
  User3: 'https://storage.googleapis.com/studyitlive_website_images/Website_Images/user3.webp',
  FooterLogo: 'https://storage.googleapis.com/studyitlive_website_images/Website_Images/footerLogo.png',
  HeaderLogo: 'https://storage.googleapis.com/studyitlive_website_images/Website_Images/headerLogo.png',
  FirstCarousel: 'https://storage.googleapis.com/studyitlive_website_images/Website_Images/carousel/1.png',
  SecondCarousel: 'https://storage.googleapis.com/studyitlive_website_images/Website_Images/carousel/2.png',
  ThirdCarousel: 'https://storage.googleapis.com/studyitlive_website_images/Website_Images/carousel/3.png',
  SilLogo: 'https://storage.googleapis.com/studyitlive_website_images/Website_Images/sil-logo.png',
  EmptyState: 'https://storage.googleapis.com/studyitlive_website_images/Website_Images/Empty_State_Image.png',
  DocumentIcon: 'https://storage.googleapis.com/studyitlive_website_images/Website_Images/documentIcon.png',
  TrashIcon: 'https://storage.googleapis.com/studyitlive_website_images/Website_Images/trashIcon.png',
  VideoThumbNail: 'https://storage.googleapis.com/studyitlive_website_images/Website_Images/videoThumbnail.png',
  TickCircle: 'https://storage.googleapis.com/studyitlive_website_images/Website_Images/tick-circle.png',
  CloseCircle: 'https://storage.googleapis.com/studyitlive_website_images/Website_Images/close-circle.png',
  orbitalCarousel1:
    'https://storage.googleapis.com/studyitlive_website_images/Website_Images/orbitalCarousel/orbitalCarousel1.png',
  orbitalCarousel2:
    'https://storage.googleapis.com/studyitlive_website_images/Website_Images/orbitalCarousel/orbitalCarousel2.png',
  orbitalCarousel3:
    'https://storage.googleapis.com/studyitlive_website_images/Website_Images/orbitalCarousel/orbitalCarousel3.png',
  orbitalCarousel4:
    'https://storage.googleapis.com/studyitlive_website_images/Website_Images/orbitalCarousel/orbitalCarousel4.png',
  orbitalCarousel5:
    'https://storage.googleapis.com/studyitlive_website_images/Website_Images/orbitalCarousel/orbitalCarousel5.png',
  orbitalCarousel6:
    'https://storage.googleapis.com/studyitlive_website_images/Website_Images/orbitalCarousel/orbitalCarousel6.png',
  orbitalCarousel7:
    'https://storage.googleapis.com/studyitlive_website_images/Website_Images/orbitalCarousel/orbitalCarousel7.png',
};

export const homeCarouselItems = [
  {
    src: imageUrls.BoyPack,
    description:
      'I have been able to balance my side job with classes and school activities since the hybrid structure was adopted by my school.',
    author: 'David Brown',
  },
  {
    src: imageUrls.GirlTree,
    description: 'The hybrid structure has allowed me to excel in both my studies and extracurricular activities.',
    author: 'Emily Davis',
  },
  {
    src: imageUrls.BoyLap,
    description:
      'Thanks to the hybrid learning model, I can now manage my time more effectively between school and personal projects.',
    author: 'James Wilson',
  },
];

export const homeOrbitalCarouselItems = [
  {
    name: 'David Wilson',
    title: 'Marketing Specialist',
    image: imageUrls.orbitalCarousel1,
    quote: 'StudyItLive has given me the flexibility to learn new skills at my own pace.',
  },
  {
    name: 'Jane Doe',
    title: 'Software Engineer',
    image: imageUrls.orbitalCarousel2,
    quote: 'The variety of courses available on StudyItLive has significantly boosted my career growth.',
  },
  {
    name: 'John Smith',
    title: 'Entrepreneur',
    image: imageUrls.orbitalCarousel3,
    quote: 'StudyItLive has provided me with the tools to expand my business knowledge.',
  },
  {
    name: 'Dr K.S Adewole',
    title: 'Subject Lecturer',
    image: imageUrls.orbitalCarousel4,
    quote:
      'I have been able to balance my side job with classes and school activities since the hybrid structure was adopted by my school.',
  },
  {
    name: 'Sophia Lee',
    title: 'Data Analyst',
    image: imageUrls.orbitalCarousel5,
    quote: 'The courses on StudyItLive have enhanced my analytical skills and career prospects.',
  },
  {
    name: 'Michael Brown',
    title: 'Project Manager',
    image: imageUrls.orbitalCarousel6,
    quote: 'Managing projects has become more efficient with the knowledge gained from StudyItLive.',
  },
  {
    name: 'Emily Johnson',
    title: 'HR Manager',
    image: imageUrls.orbitalCarousel7,
    quote: 'StudyItLive has made it easier to develop and implement effective HR strategies.',
  },
];

export const DEFAULT_ERROR_MESSAGE = 'Sorry There Was An Issue Please Try Again Later';
