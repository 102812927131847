import React from 'react';

const CourseCardSkeleton = () => {
  return (
    <div className="grid lg:grid-cols-12 gap-8 flex-grow">
      {Array(8)
        .fill(0)
        .map((_, index) => (
          <div
            key={index}
            className="h-[272px] col-span-6 lg:col-span-4 2xl:col-span-3 4k:col-span-4 bg-white shadow rounded animate-pulse"
          >
            <div className="w-full rounded p-4">
              <div className="w-full p-3 rounded-lg h-48 4k:h-[450px] bg-gray-300"></div>
              <div className="py-3 mb-0">
                <div className="h-4 bg-gray-300 rounded w-3/4 mb-2"></div>
                <div className="flex justify-between items-center">
                  <div className="bg-gray-300 h-4 w-1/2"></div>
                  <div className="bg-gray-300 h-4 w-1/4"></div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default CourseCardSkeleton;
