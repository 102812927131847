import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import LogoComponent from '../../../components/LogoComponent';
import MenuItem from '../../../components/MenuItem';
import { UserIcon, BookIcon, LogoutIcon, OverviewIcon, CourseIcon } from '../../../assets/svg-components';
import { logout } from '../../../utils/utilMethods';

const SideNav = () => {
  const { pathname } = useLocation();

  const handleLogOut = async () => {
    await logout();
  };

  return (
    <div className="sticky top-0 h-screen bg-white overflow-auto shadow-sm">
      <div className="mt-4 mb-16">
        <LogoComponent />
      </div>
      <div className="mx-1">
        <Link className="no-underline" to="/lecturer/courses">
          <MenuItem title="Courses" icon={CourseIcon} active={/^\/lecturer\/courses(\/|$)/.test(pathname)} />
        </Link>
        <Link className="no-underline" to="/lecturer/media">
          <MenuItem title="Book Shelf" icon={BookIcon} active={/^\/lecturer\/(media$|view\/\w+)$/.test(pathname)} />
        </Link>
        {/* <Link className="no-underline" to="/lecturer/earnings">
          <MenuItem title="Earnings" icon={OverviewIcon} active={pathname === '/lecturer/earnings} />
        </Link> */}
        <Link className="no-underline" to="/lecturer/profile">
          <MenuItem title="Profile" icon={UserIcon} active={pathname === '/lecturer/profile'} />
        </Link>
        <hr className="my-4" />
        <div onClick={handleLogOut} className="custom-pointer">
          <MenuItem active={false} title="Log Out" icon={LogoutIcon} />
        </div>
      </div>
    </div>
  );
};

export default SideNav;
