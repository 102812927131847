import React from 'react';

const LecturerMediaLibrarySkeleton: React.FC = () => {
  return (
    <div className="flex flex-col bg-white border-0 shadow-sm page-card-container animate-pulse">
      <div className="flex content-center justify-between m-6">
        <div className="h-6 w-32 bg-gray-300 rounded"></div>
        <div className="h-12 w-36 bg-gray-300 rounded"></div>
      </div>
      <div className="flex-grow px-6 pb-6">
        <div className="w-full border-b border-collapse table-auto border-x border-slate-100">
          <div className="border-0 bg-dashboard">
            <div className="flex justify-between py-3 px-8">
              <div className="h-4 w-24 bg-gray-300 rounded mr-64"></div>
              <div className="h-4 w-24 bg-gray-300 rounded"></div>
              <div className="h-4 w-24 bg-gray-300 rounded"></div>
              <div className="h-4 w-24"></div>
            </div>
          </div>
          <div>
            {[...Array(5)].map((_, index) => (
              <div key={index} className="flex items-center border-b-2 py-2 pl-4 pr-24 justify-between">
                <div className="flex items-center mr-[120px]">
                  <div className="h-10 w-10 bg-gray-300 rounded shrink"></div>
                  <div className="h-4 w-48 bg-gray-300 rounded mx-2"></div>
                </div>
                <div className="h-4 w-24 bg-gray-300 rounded"></div>
                <div className="h-4 w-24 bg-gray-300 rounded"></div>
                <div className="h-6 w-6 bg-gray-300 rounded cursor-pointer"></div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="pb-4 flex justify-center">
        <div className="h-8 w-48 bg-gray-300 rounded"></div>
      </div>
    </div>
  );
};

export default LecturerMediaLibrarySkeleton;
