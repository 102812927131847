import { EnrolledSection } from '../types';
import axios from '../utils/axios';
import { useQuery } from '@tanstack/react-query';

export const useEnrolledSections = (courseId: string, ids: string[]) => {
  return useQuery<EnrolledSection[], Error>({
    queryKey: [ids],
    queryFn: async () => {
      if (!courseId || ids.length === 0) return [];

      const promises = ids.map((id) =>
        axios
          .get(`/enrollment/courses/${courseId}/sections/${id}`)
          .then((response) => response.data)
          .catch((error) => ({ error }))
      );

      const results = await Promise.allSettled(promises);
      const data = results
        .filter((result) => result.status === 'fulfilled')
        .map((result: PromiseFulfilledResult<EnrolledSection>) => result.value);

      return data;
    },
    enabled: courseId.length > 0 && ids.length > 0,
  });
};
