import React, { useState, useEffect } from 'react';
import axios from '../../utils/axios';
import { AxiosError } from 'axios';
import { Alert } from 'flowbite-react';
import { EnrolledCourse, StudentEnrolledCourseResponse } from '../../types';
import { DEFAULT_ERROR_MESSAGE, imageUrls } from '../../utils/constants';
import { LoadingSpinner, Pagination, SearchBar } from '../../components';
import { CourseCardSkeleton, StudentCourseComponent } from './components';

const StudentPersonalCourses = () => {
  const [enrolledCourses, setEnrolledCourses] = useState<StudentEnrolledCourseResponse[]>([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [coursesPerPage] = useState(8);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');

  const getEnrolledCourses = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`/enrollment/courses`, {
        params: {
          page: currentPage,
          limit: coursesPerPage,
          title: searchQuery,
        },
      });
      setEnrolledCourses(res.data.results);
      setTotalPages(Math.ceil(res.data.totalDocuments / coursesPerPage));
      setLoading(false);
    } catch (err) {
      if (err instanceof AxiosError) {
        setLoading(false);
        const errorMessage: string = err.response ? err.response.data.message : DEFAULT_ERROR_MESSAGE;
        setError(errorMessage);
      } else {
        setError(err.message);
      }
    }
  };

  useEffect(() => {
    getEnrolledCourses();
  }, [currentPage]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    setCurrentPage(1);
  };

  return (
    <div className="pt-3 h-full">
      {error && (
        <Alert color="failure" className="mb-4">
          <p className="font-medium font-lato">{error}</p>
        </Alert>
      )}
      <div className=" w-full md:w-1/2 mx-auto mb-8">
        <SearchBar onSearch={handleSearch} />
      </div>
      {loading ? (
        <CourseCardSkeleton />
      ) : enrolledCourses && enrolledCourses.length > 0 ? (
        <div className="flex flex-col h-full light-bg-grey">
          <div className="grid lg:grid-cols-12 gap-4 flex-grow">
            {enrolledCourses.map((course: StudentEnrolledCourseResponse) => (
              <StudentCourseComponent course={course.course} key={course.course.id} />
            ))}
          </div>
          <div className="mx-auto mt-auto pt-10">
            <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
          </div>
        </div>
      ) : (
        <div className="flex justify-center content-center h-100">
          <div>
            <img src={imageUrls.EmptyState} alt="Box" className="mx-auto block pb-3 cursor-pointer h-48" />
            <p className="text-center font-lato text-lg font-bold ">Sorry, there is no content here at the moment.</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default StudentPersonalCourses;
