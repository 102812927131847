import React, { useState, useEffect } from 'react';
import axios from '../../utils/axios';
import { useParams } from 'react-router-dom';
import { LoadingSpinner, MediaViewer } from '../../components';
import StudentCourseProgressView from './components/StudentCourseProgressView';
import { EnrolledCourse, Media } from '../../types';
import { AxiosError } from 'axios';
import { customModalTheme } from '../../custom-themes/customModal';
import { Modal } from 'flowbite-react';
import { DEFAULT_ERROR_MESSAGE, imageUrls, TransactionStatusType } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';

const StudentCourseViewer = () => {
  const navigate = useNavigate();
  const [mediaObject, setMediaObject] = useState<Media>();
  const [mediaLoading, setMediaLoading] = useState(false);
  const [course, setCourse] = useState<EnrolledCourse>();
  const [courseLoading, setCourseLoading] = useState(false);
  const { courseId } = useParams();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [successModalVisibility, setSuccessModalVisibility] = useState(false);
  const [errorModalVisibility, setErrorModalVisibility] = useState(false);

  const getMediaObject = async (media: Media) => {
    setMediaLoading(true);
    setMediaObject(media);
    setMediaLoading(false);
  };

  const getCourseObject = async () => {
    try {
      setCourseLoading(true);
      const res = await axios.get(`/enrollment/courses/${courseId}`);
      const courseData: EnrolledCourse = res.data;
      setCourse(courseData);

      const firstSectionId: string = courseData.sections[0];
      if (firstSectionId) {
        getEnrolledSection(firstSectionId);
      }
      setCourseLoading(false);
    } catch (err) {
      if (err instanceof AxiosError) {
        const errorMessage: string = err.response ? err.response.data.message : DEFAULT_ERROR_MESSAGE;
        setError(errorMessage);
      } else {
        setError(err.message);
      }
      setCourseLoading(false);
    }
  };

  const getEnrolledSection = async (sectionId: string) => {
    try {
      setMediaLoading(true);
      const res = await axios.get(`/enrollment/courses/${courseId}/sections/${sectionId}`);
      const firstMediaObject: Media = res.data.media[0].mediaId;
      if (firstMediaObject) {
        setMediaObject(firstMediaObject);
      }
      setMediaLoading(false);
    } catch (err) {
      if (err instanceof AxiosError) {
        const errorMessage: string = err.response ? err.response.data.message : DEFAULT_ERROR_MESSAGE;
        setError(errorMessage);
      } else {
        setError(err.message);
      }
      setMediaLoading(false);
    }
  };

  const confirmPayment = () => {
    setLoading(true);
    setSuccessModalVisibility(true);
    setErrorModalVisibility(true);
    getCourseObject();
  };

  const handleMediaSelect = (media: Media) => {
    getMediaObject(media);
  };

  const handleClose = (closeOption: string) => {
    if (closeOption == TransactionStatusType.SUCCESSFUL) {
      setSuccessModalVisibility(false);
    } else {
      setErrorModalVisibility(false);
      navigate(`/student/course/${courseId}`);
    }
  };

  useEffect(() => {
    getCourseObject();
  }, []);

  return (
    <>
      <div className="bg-white rounded page-card-container">
        <div className="grid h-full gap-4 p-6 lg:grid-cols-12">
          <div className="lg:col-span-8 h-full">
            {mediaLoading ? (
              <div className="w-full h-full animate-pulse bg-white flex flex-col py-4">
                <div className="flex-grow bg-gray-300 mb-4"></div>
                <div className="h-8 w-64 bg-gray-300"></div>
              </div>
            ) : (
              <MediaViewer mediaObject={mediaObject!} />
            )}
          </div>
          <div className="lg:col-span-4 flex flex-col h-full overflow-y-auto">
            <div className="p-3 mb-3 text-lg font-medium bg-white rounded shadow font-lato">
              <h1 className="mb-0 text-lg font-semibold capitalize font-lato">Course content</h1>
            </div>
            {courseLoading ? (
              <div className="w-full h-full animate-pulse flex flex-col gap-2">
                <div className="h-20 w-full bg-gray-300 rounded flex flex-row justify-between items-center px-8">
                  <div className="bg-gray-400 h-6 w-28 rounded"></div>
                  <div className="bg-gray-400 h-6 w-6 rounded"></div>
                </div>
                <div className="h-20 w-full bg-gray-300 rounded flex flex-row justify-between items-center px-8">
                  <div className="bg-gray-400 h-6 w-28 rounded"></div>
                  <div className="bg-gray-400 h-6 w-6 rounded"></div>
                </div>
                <div className="h-20 w-full bg-gray-300 rounded flex flex-row justify-between items-center px-8">
                  <div className="bg-gray-400 h-6 w-28 rounded"></div>
                  <div className="bg-gray-400 h-6 w-6 rounded"></div>
                </div>
              </div>
            ) : (
              <StudentCourseProgressView course={course} onMediaSelect={handleMediaSelect} />
            )}
          </div>
        </div>
      </div>

      <Modal
        show={successModalVisibility}
        onClose={() => handleClose(TransactionStatusType.SUCCESSFUL)}
        size="md"
        position="center"
        theme={customModalTheme}
      >
        <Modal.Header className="justify-center p-4 border-0">
          <img src={imageUrls.TickCircle} className="mx-auto" />
          <h3 className="font-bold font-lato text-[#34C759]">Payment Successful</h3>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <p className="capitalize font-poppins font-medium text-sm">
            Your payment has been processed securely via Paystack.
          </p>
        </Modal.Body>
        <Modal.Footer className="pt-1 border-0">
          <button
            className="w-full h-10 text-lg font-medium rounded custom-secondary-btn font-poppins"
            onClick={() => handleClose(TransactionStatusType.SUCCESSFUL)}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={errorModalVisibility}
        onClose={() => handleClose(TransactionStatusType.FAILURE)}
        size="md"
        position="center"
        theme={customModalTheme}
      >
        <Modal.Header className="justify-center p-4 border-0">
          <img src={imageUrls.CloseCircle} className="mx-auto" />
          <h3 className="font-bold font-lato text-[#EF5253]">Payment Failed</h3>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <p className="capitalize font-poppins font-medium text-sm">
            Unfortunately, we couldn’t process your payment via Paystack. Please contact our customer service for
            assistance.
          </p>
        </Modal.Body>
        <Modal.Footer className="pt-1 border-0">
          <button
            className="w-full h-10 text-lg font-medium rounded custom-secondary-btn font-poppins"
            onClick={() => handleClose(TransactionStatusType.FAILURE)}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default StudentCourseViewer;
