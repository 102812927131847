/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Alert } from 'flowbite-react';
import axios from '../../utils/axios';
import { UploadResponse } from '../../components/UploadComponent/types';
import { AxiosError } from 'axios';
import { object, string, ValidationError } from 'yup';
import { Course } from '../../types';
import { customModalTheme } from '../../custom-themes/customModal';
import { useDeleteFlagStore } from '../../utils/store';
import { ToastContainer, toast } from 'react-toastify';
import { DEFAULT_ERROR_MESSAGE, imageUrls, MediaType } from '../../utils/constants';
import { LoadingSpinner, Pagination, SearchBar, UploadComponent } from '../../components';
import { showErrorToast } from '../../utils/errorHandler';
import { useSearch } from '../SearchContext';
import { CourseCardSkeleton } from './components';

const LecturerCourses = () => {
  const dFlag = useDeleteFlagStore((state: any) => state.dFlag);
  const setDeletionFlag = useDeleteFlagStore((state: any) => state.setDeletionFlag);
  const [visible, setVisible] = useState(false);
  const [closable, setClosable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [coursesPerPage] = useState(8);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [startUpload, setStartUpload] = useState(false);
  const [uploadError, setUploadError] = useState('');
  const [validationErrors, setValidationErrors] = useState<Record<string, string>>();
  const [error, setError] = useState('');
  const [courses, setCourses] = useState<Course[]>();
  const [searchQuery, setSearchQuery] = useState('');
  const [courseForm, setCourseForm] = useState({
    title: '',
    description: '',
  });

  const validationSchema = object({
    title: string().required('A Title Is Required'),
    description: string()
      .required('A Description Is Required')
      .min(10, 'Description Must Be A Minimun Of 10 Characters')
      .max(100, 'Description Can Only Be A Maximum Of 100 Characters'),
  });

  const handleShow = () => setVisible(true);

  const handleUploadError = (err?: string) => {
    if (!err) return;

    setUploadError(err ?? '');
    const errorMessage = err || DEFAULT_ERROR_MESSAGE;
    showErrorToast(errorMessage);
    setClosable(true);
    setStartUpload(false);
  };

  const showCourseDeletionToast = () =>
    toast.success('Course Deleted Successfully', {
      position: 'top-right',
      autoClose: 3000,
      className: 'font-lato text-base font-medium',
      hideProgressBar: true,
      theme: 'light',
      containerId: 'courseDeletionToast',
    });

  const showCourseCreationToast = () =>
    toast.success('Course Created Successfully', {
      position: 'top-right',
      autoClose: 3000,
      className: 'font-lato text-base font-medium',
      hideProgressBar: true,
      theme: 'light',
      containerId: 'courseCreationToast',
    });

  const handleChange = (e: any) => {
    setCourseForm({
      ...courseForm,
      [e.target.name]: e.target.value,
    });
  };

  const handleUpload = async () => {
    setClosable(false);
    setUploadError('');
    try {
      await validationSchema.validate(courseForm, { abortEarly: false });
      setUploadError('');
      setStartUpload(true);
    } catch (error) {
      if (error instanceof ValidationError) {
        let formErrors: Record<string, string> = {};
        error.inner.forEach((err) => {
          if (err.path) {
            formErrors[err.path] = err.message;
          }
        });
        setValidationErrors(formErrors);
      }
      setStartUpload(false);
      setClosable(true);
    }
  };

  const handleClose = () => {
    setCourseForm({ title: '', description: '' });
    setUploadError('');
    setValidationErrors({});
    setVisible(false);
    setStartUpload(false);
  };

  const getCourses = async () => {
    setError('');
    try {
      setLoading(true);
      const res = await axios.get(`instructors/courses`, {
        params: {
          page: currentPage,
          limit: coursesPerPage,
          title: searchQuery,
        },
      });
      const { results, totalDocuments } = res.data;
      setCourses(results);
      setTotalPages(Math.ceil(totalDocuments / coursesPerPage));
      setLoading(false);
    } catch (err) {
      if (err instanceof AxiosError) {
        const errorMessage: string = err.response ? err.response.data.message : DEFAULT_ERROR_MESSAGE;
        setError(errorMessage);
      } else {
        setError(err.message);
      }
    }
  };

  const finalizeCourseCreation = async (cloudObj: UploadResponse) => {
    setUploadError('');
    const courseData = {
      title: courseForm.title,
      description: courseForm.description,
      photoUrl: cloudObj.url,
      price: 0,
    };
    try {
      await axios.post(`/courses`, courseData);
      getCourses();
      showCourseCreationToast();
      setClosable(true);
      handleClose();
    } catch (err) {
      showErrorToast();
      setClosable(true);
      setStartUpload(false);
    }
  };

  useEffect(() => {
    if (dFlag) {
      showCourseDeletionToast();
      setDeletionFlag(false);
    }
    getCourses();
  }, [currentPage, searchQuery]);

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-col items-center justify-between pb-7 gap-4">
        <div className="flex items-center justify-between w-full">
          <h1 className="text-lg md:text-2xl font-medium capitalize font-lato">All Courses</h1>
          <button
            className="h-12 text-base font-medium rounded w-24 md:w-36 font-poppins custom-primary-btn"
            onClick={handleShow}
          >
            + Add New
          </button>
        </div>
        <div className="w-full md:w-1/2 mx-auto">
          <SearchBar onSearch={handleSearch} />
        </div>
      </div>
      {error && (
        <Alert color="failure" className="mb-4">
          <p className="font-medium font-lato">{error}</p>
        </Alert>
      )}
      {loading ? (
        <CourseCardSkeleton />
      ) : courses && courses.length >= 1 ? (
        <div className="flex flex-col h-full light-bg-grey gap-8 py-2">
          <div className="grid lg:grid-cols-12 gap-8 flex-grow">
            {courses?.map((course) => (
              <div
                key={`course-${course.id}-column`}
                className="col-span-6 lg:col-span-4 2xl:col-span-3 4k:col-span-4 drop-shadow-md lg-drop-shadow-none"
              >
                <Link
                  to={{
                    pathname: `/lecturer/courses/${course.id}`,
                  }}
                  className="text-black text-decoration-none"
                >
                  <div className="w-full h-64 4k:h-[500px] bg-white rounded shadow-sm hover:shadow-primary10 hover:shadow-md">
                    <img src={course.photoUrl} alt="" className="w-full p-3 rounded-lg h-52 4k:h-[450px]" />

                    <div className="px-3 mb-0">
                      <p className="text-lg 4k:text-2xl font-semibold capitalize truncate font-lato">{course.title}</p>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
          <div className="mx-auto mt-auto pt-10">
            <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
          </div>
        </div>
      ) : (
        <div className="flex flex-col justify-center items-cente flex-grow">
          <img src={imageUrls.EmptyState} alt="Box" className="mx-auto block pb-2 h-48" />
          <p className="text-center font-lato text-base font-medium capitalize mb-1">No Courses Available</p>
          <p className="text-center font-lato text-sm font-normal capitalize text-slate-400">
            Sorry, there is no content here at the moment.
          </p>
        </div>
      )}

      <Modal
        show={visible}
        onClose={() => closable && handleClose()}
        size="md"
        position="center"
        theme={customModalTheme}
      >
        <Modal.Header className={`justify-center p-4 border-0 ${!closable ? 'opacity-50' : ''}`}>
          <h3 className="font-bold font-lato">Create New Course</h3>
        </Modal.Header>
        <Modal.Body className="pt-0">
          {uploadError && (
            <Alert color="failure" className="mb-4">
              <p className="font-medium font-lato">{uploadError}</p>
            </Alert>
          )}
          <form>
            <label htmlFor="title" className="block pb-2 text-lg font-normal text-gray-400 font-lato">
              Course Title
            </label>
            <input
              type="text"
              name="title"
              id="title"
              value={courseForm.title}
              onChange={handleChange}
              className="border form-input-fields border-slate-500"
              required
            ></input>
            {validationErrors?.title && <p className="text-base text-red-600 font-lato">{validationErrors.title}</p>}

            <label htmlFor="description" className="block pt-2 pb-2 text-lg font-normal text-gray-400 font-lato">
              Description
            </label>
            <input
              type="text"
              name="description"
              id="description"
              value={courseForm.description}
              onChange={handleChange}
              className="border form-input-fields border-slate-500"
              required
            ></input>
            {validationErrors?.description && (
              <p className="text-base text-red-600 font-lato">{validationErrors.description}</p>
            )}

            <div className="pt-4">
              <label htmlFor="description" className="block pb-2 text-lg font-normal text-gray-400 font-lato">
                Course Thumbnail
              </label>
              <UploadComponent
                acceptedFileTypes={['image/*']}
                mediaType={MediaType.IMAGE}
                onError={handleUploadError}
                onSuccess={finalizeCourseCreation}
                startUpload={startUpload}
                filename={courseForm.title}
                imageAspectRatio="1:1"
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="pt-1 border-0">
          <button
            className={`w-full h-10 text-lg font-medium rounded custom-secondary-btn font-poppins ${
              !closable ? 'opacity-50 cursor-not-allowed hover:bg-gray-200' : ''
            }`}
            onClick={handleClose}
            disabled={!closable}
          >
            Close
          </button>
          <button
            className={`w-full h-10 text-lg font-medium rounded custom-primary-btn font-poppins ${
              !closable ? 'opacity-50 cursor-not-allowed hover:bg-gray-200' : ''
            }`}
            onClick={handleUpload}
            disabled={!closable}
          >
            Create Course
          </button>
        </Modal.Footer>
      </Modal>

      <ToastContainer containerId="courseCreationToast" />
      <ToastContainer containerId="courseDeletionToast" />
      <ToastContainer containerId="errorToast" stacked />
    </div>
  );
};

export default LecturerCourses;
