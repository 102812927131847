import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { StudentCourse, StudentCourseViewer, StudentCourses, StudentProfile } from '../modules/StudentPortal';
import StudentLayout from '../modules/StudentPortal/components/StudentLayout';
import { ProtectedRoute } from '../components';
import { UserType } from '../types';
import { SearchProvider } from '../modules/SearchContext';

const StudentRoutes = () => {
  return (
    <Routes>
      <Route
        path="/student"
        element={
          <ProtectedRoute allowedRoles={[UserType.Student]}>
            <SearchProvider>
              <StudentLayout />
            </SearchProvider>
          </ProtectedRoute>
        }
      >
        <Route path="course/:courseId" element={<StudentCourse />} />
        <Route path="course" element={<StudentCourses />} />
        <Route path="course/:courseId/view" element={<StudentCourseViewer />} />
        <Route path="profile" element={<StudentProfile />} />
      </Route>
    </Routes>
  );
};
export default StudentRoutes;
