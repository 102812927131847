import { useQuery } from '@tanstack/react-query';
import axios from '../utils/axios';
import { Course } from '../types';

export const useCourse = (id: string) => {
  return useQuery<Course, Error>({
    queryKey: ['course'],
    queryFn: async () => {
      const res = await axios.get(`/courses/${id}`);
      const data = res.data;
      return data;
    },
  });
};

export const useCourses = (ids: string[]) => {
  return useQuery<Course[], Error>({
    queryKey: ['courses'],
    queryFn: async () => {
      const promises = ids.map((id) =>
        axios
          .get(`/courses/${id}`)
          .then((response) => response.data)
          .catch((error) => ({ error }))
      );

      const results = await Promise.allSettled(promises);
      const data = results
        .filter((result) => result.status === 'fulfilled')
        .map((result: PromiseFulfilledResult<Course>) => result.value);

      return data;
    },
    enabled: ids.length > 0,
  });
};

export const useInstructorCourse = (id: string) => {
  return useQuery<Course, Error>({
    queryKey: ['course'],
    queryFn: async () => {
      const res = await axios.get(`instructors/courses/${id}`);
      const data = res.data;
      return data;
    },
  });
};
