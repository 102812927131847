import React, { useEffect, useState } from 'react';
import { UploadIcon } from '../../../assets/svg-components';
import { Course, Visibility } from '../../../types';
import { object, string, number, ValidationError } from 'yup';
import axios from '../../../utils/axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useDeleteFlagStore } from '../../../utils/store';
import { toast, ToastContainer } from 'react-toastify';
import { Modal } from 'flowbite-react';
import { ImageUploader } from '../../../components';
import { UploadResponse } from '../../../components/UploadComponent/types';
import { customModalTheme } from '../../../custom-themes/customModal';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast } from '../../../utils/errorHandler';
import { DEFAULT_ERROR_MESSAGE } from '../../../utils/constants';
import { useInstructorCourse } from '../../../custom-hooks/useCourse';

const LecturerCourseEditor = () => {
  const { id: courseId } = useParams();
  const navigate = useNavigate();
  const { data } = useInstructorCourse(courseId || '');
  const [editable, setEditable] = useState(false);
  const [deletionModalVisibility, setDeletionModalVisibility] = useState(false);
  const [deletionModalClosable, setDeletionModalClosable] = useState(true);
  const [courseValidationErrors, setCourseValidationErrors] = useState<Record<string, string>>();
  const setDeletionFlag = useDeleteFlagStore((state: any) => state.setDeletionFlag);
  const [imageUploaderState, setImageUploaderState] = useState({
    show: false,
    loading: false,
  });
  const [thumbnailUploaderState, setThumbnailUploaderState] = useState({
    show: false,
    loading: false,
  });
  const [courseForm, setCourseForm] = useState<Course>({
    id: '',
    title: '',
    description: '',
    photoUrl: '',
    price: 0,
    thumbnailUrl: '',
    visibility: '',
    sections: [],
  });

  const showCourseEditToast = () =>
    toast.success('Course Edited Successfully', {
      position: 'top-right',
      autoClose: 3000,
      className: 'font-lato text-base font-medium',
      hideProgressBar: true,
      theme: 'light',
      containerId: 'courseEditToast',
    });

  const validationCourseSchema = object({
    title: string().required('A Title Is Required'),
    price: number().required('A Price Is Required'),
    description: string()
      .required('A Description Is Required')
      .min(10, 'Description Must Be A Minimun Of 10 Characters')
      .max(100, 'Description Can Only Be A Maximum Of 100 Characters'),
  });

  const setUploadedImageUrl = (mediaObj: UploadResponse) => {
    setImageUploaderState({ ...imageUploaderState, loading: true });
    setCourseForm({ ...courseForm, photoUrl: mediaObj.url });
    setImageUploaderState({ ...imageUploaderState, loading: false });
  };

  const setUploadedThumbnailUrl = (mediaObj: UploadResponse) => {
    setThumbnailUploaderState({ ...thumbnailUploaderState, loading: true });
    setCourseForm({ ...courseForm, thumbnailUrl: mediaObj.url });
    setThumbnailUploaderState({ ...thumbnailUploaderState, loading: false });
  };

  const showImageUploader = () => {
    setImageUploaderState({ ...imageUploaderState, show: true });
  };

  const showThumbnailUploader = () => {
    setThumbnailUploaderState({ ...thumbnailUploaderState, show: true });
  };

  const closeImageUploader = () => {
    setImageUploaderState({ ...imageUploaderState, show: false });
  };

  const closeThumbnailUploader = () => {
    setThumbnailUploaderState({ ...thumbnailUploaderState, show: false });
  };

  const deleteCourse = async () => {
    setDeletionModalClosable(false);
    try {
      await axios.delete(`/courses/${courseId}`);
      setDeletionFlag(true);
      navigate(`/lecturer/courses`);
    } catch (err) {
      const errorMessage = err.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      setDeletionModalClosable(true);
      setDeletionModalVisibility(false);
      showErrorToast(errorMessage);
    }
  };

  const queryClient = useQueryClient();
  const { mutate: mutateCourseData } = useMutation({
    mutationFn: async () => {
      setCourseValidationErrors({});
      await validationCourseSchema.validate(courseForm, { abortEarly: false });
      await axios.put(`/courses/${courseId}`, {
        title: courseForm.title,
        description: courseForm.description,
        photoUrl: courseForm.photoUrl,
        price: courseForm.price,
        thumbnailUrl: courseForm.thumbnailUrl,
        visibility: courseForm.visibility,
      });
    },
    mutationKey: ['course'],
    onSuccess: () => {
      setEditable(false);
      showCourseEditToast();
      queryClient.invalidateQueries({ queryKey: ['course'] });
    },
    onError: (error: any) => {
      if (error instanceof ValidationError) {
        let formErrors: Record<string, string> = {};
        error.inner.forEach((err) => {
          if (err.path) {
            formErrors[err.path] = err.message;
          }
        });
        setCourseValidationErrors(formErrors);
      } else {
        showErrorToast();
      }
    },
  });

  const handleCourseVisibility = (visibility: string) => {
    if (visibility === Visibility.ENROLLED || visibility === Visibility.PUBLIC) {
      setCourseForm((prevForm) => ({
        ...prevForm,
        visibility: Visibility.HIDDEN,
      }));
    } else {
      setCourseForm((prevForm) => ({
        ...prevForm,
        visibility: Visibility.PUBLIC,
      }));
    }
  };

  const handleCourseChange = (e: any) => {
    setCourseForm({ ...courseForm, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (data) {
      setCourseForm(data);
    }
  }, [data]);

  return (
    <div className="w-full lg:w-1/2">
      <form>
        <div className="grid gap-4 pb-6 lg:grid-cols-12">
          <div className="col-span-8">
            <label htmlFor="courseTitle" className="block pb-4 text-lg font-normal font-lato text-slate-400">
              Course Title
            </label>
            <input
              type="text"
              id="title"
              name="title"
              className={`form-input-fields border border-slate-300 ${!editable ? 'bg-gray-200' : ''}`}
              value={courseForm?.title}
              onChange={handleCourseChange}
              required
              disabled={!editable}
            />
            {courseValidationErrors?.title && (
              <p className="text-base text-red-600 font-lato">{courseValidationErrors.title}</p>
            )}
          </div>

          <div className="col-span-4">
            <label htmlFor="price" className="block pb-4 text-lg font-normal font-lato text-slate-400">
              Pricing
            </label>
            <input
              type="text"
              id="price"
              name="price"
              className={`form-input-fields border border-slate-300 ${!editable ? 'bg-gray-200' : ''}`}
              value={courseForm?.price}
              onChange={handleCourseChange}
              required
              disabled={!editable}
            />
            {courseValidationErrors?.price && (
              <p className="text-base text-red-600 font-lato">{courseValidationErrors.price}</p>
            )}
          </div>
        </div>

        <label htmlFor="description" className="block pb-4 text-lg font-normal font-lato text-slate-400">
          Description
        </label>
        <textarea
          name="description"
          id="description"
          value={courseForm?.description}
          onChange={handleCourseChange}
          className={`textarea-input-fields border border-slate-300 mb-6 ${!editable ? 'bg-gray-200' : ''}`}
          disabled={!editable}
        />
        {courseValidationErrors?.desription && (
          <p className="text-base text-red-600 font-lato">{courseValidationErrors.description}</p>
        )}

        <label htmlFor="price" className="block pb-4 text-lg font-normal font-lato text-slate-400">
          Upload Thumbnail Image
        </label>
        <div className="relative mb-6" onClick={() => editable && showImageUploader()}>
          <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
            <UploadIcon />
          </div>
          <input
            type="text"
            id="photoUrl"
            name="photoUrl"
            className={`w-full h-12 rounded border border-slate-300 indent-6 ${!editable ? 'bg-gray-200' : ''}`}
            value={courseForm.photoUrl}
            onChange={handleCourseChange}
            readOnly
            disabled={!editable}
          />
        </div>

        <label htmlFor="price" className="block pb-4 text-lg font-normal font-lato text-slate-400">
          Upload Banner Image
        </label>
        <div className="relative mb-6" onClick={() => editable && showThumbnailUploader()}>
          <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
            <UploadIcon />
          </div>
          <input
            type="text"
            id="thumbnailUrl"
            name="thumbnailUrl"
            className={`w-full h-12 rounded border border-slate-300 indent-6 ${!editable ? 'bg-gray-200' : ''}`}
            value={courseForm.thumbnailUrl}
            onChange={handleCourseChange}
            readOnly
            disabled={!editable}
          />
        </div>

        <label className="inline-flex items-center cursor-pointer">
          <input
            type="checkbox"
            defaultChecked={
              courseForm.visibility === Visibility.ENROLLED || courseForm.visibility === Visibility.PUBLIC
                ? true
                : false
            }
            className="sr-only peer"
            onClick={() => handleCourseVisibility(courseForm.visibility)}
            disabled={!editable}
          />
          <div className="relative w-11 h-6 bg-gray-400 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
          <span className="text-lg font-medium ms-3 font-lato">Show Course</span>
        </label>
      </form>
      <div className="pt-6">
        {editable ? (
          <div>
            <button
              className="h-12 font-bold rounded me-4 custom-primary-btn font-poppins w-36"
              onClick={() => mutateCourseData()}
            >
              Submit
            </button>
            <button
              className="h-12 font-bold rounded custom-secondary-btn font-poppins w-36"
              onClick={() => setEditable(false)}
            >
              Cancel
            </button>
          </div>
        ) : (
          <div>
            <button
              className="h-12 font-bold text-red-500 border border-red-500 rounded me-4 hover:text-white hover:bg-red-500 font-poppins w-36"
              onClick={() => setDeletionModalVisibility(true)}
            >
              Delete
            </button>
            <button
              className="h-12 font-bold rounded custom-primary-btn font-poppins w-36"
              onClick={(e) => {
                e.preventDefault();
                setEditable(true);
              }}
            >
              Edit
            </button>
          </div>
        )}
      </div>
      <Modal
        show={deletionModalVisibility}
        onClose={() => deletionModalClosable && setDeletionModalVisibility(false)}
        size="md"
        position="center"
        theme={customModalTheme}
      >
        <Modal.Body>
          <h1 className="text-2xl font-medium text-center capitalize font-lato">
            Are you sure you want to delete this course ?
          </h1>
        </Modal.Body>
        <Modal.Footer className="pt-2 border-0">
          <button
            className={`w-full h-10 text-lg font-medium rounded custom-secondary-btn font-poppins ${
              !deletionModalClosable ? 'opacity-50 cursor-not-allowed hover:bg-gray-200' : ''
            }`}
            disabled={!deletionModalClosable}
            onClick={() => setDeletionModalVisibility(false)}
          >
            Cancel
          </button>
          <button
            className={`w-full h-10 text-lg font-medium rounded custom-primary-btn font-poppins ${
              !deletionModalClosable ? 'opacity-50 cursor-not-allowed hover:bg-gray-200' : ''
            }`}
            disabled={!deletionModalClosable}
            onClick={() => deleteCourse()}
          >
            Yes
          </button>
        </Modal.Footer>
      </Modal>

      <ImageUploader
        imageName={courseForm.title}
        loading={imageUploaderState.loading}
        show={imageUploaderState.show}
        onHide={closeImageUploader}
        onSuccess={setUploadedImageUrl}
        imageAspectRatio="1:1"
      />

      <ImageUploader
        imageName={courseForm.title}
        loading={thumbnailUploaderState.loading}
        show={thumbnailUploaderState.show}
        onHide={closeThumbnailUploader}
        onSuccess={setUploadedThumbnailUrl}
        imageAspectRatio="7:1"
      />

      <ToastContainer containerId="courseEditToast" />
      <ToastContainer containerId="errorToast" stacked />
    </div>
  );
};

export default LecturerCourseEditor;
