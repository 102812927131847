import React from 'react';

const ProfileSkeleton = () => {
  return (
    <div className="w-100 lg:w-5/6">
      {/* Profile Picture */}
      <div className="flex flex-col items-center mb-4">
        <div className="w-[103px] h-[103px] bg-gray-300 rounded-full animate-pulse"></div>
        <div className="h-4 w-32 mt-4 mb-2 pt-3.5 pb-2 bg-gray-300 rounded"></div>
        <div className="mt-2 flex gap-2">
          <div className="h-4 w-10 bg-blue-400 rounded animate-pulse"></div>
          <div className="h-4 w-12 bg-red-400 rounded animate-pulse"></div>
        </div>
      </div>

      {/* Form Fields */}
      <div className="space-y-4">
        <div className="h-4 bg-gray-300 w-16 rounded"></div>
        <div className="flex gap-4 flex-col lg:flex-row">
          <div className="w-full h-10 bg-gray-300 lg:w-1/4 rounded animate-pulse"></div>
          <div className="w-full h-10 bg-gray-300 lg:w-1/2 rounded animate-pulse"></div>
          <div className="w-full h-10 bg-gray-300 lg:w-1/2 rounded animate-pulse"></div>
        </div>

        <div className="h-4 bg-gray-300 w-16 rounded"></div>
        <div className="h-10 bg-gray-300 w-full rounded animate-pulse"></div>

        <div className="h-4 bg-gray-300 w-16 rounded"></div>
        <div className="h-24 bg-gray-300 w-full rounded animate-pulse"></div>

        <div className="h-10 bg-blue-600 w-24 mr-auto rounded animate-pulse"></div>
      </div>
    </div>
  );
};

export default ProfileSkeleton;
