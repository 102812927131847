import React from 'react';

const StudentCourseSkeleton = () => {
  return (
    <div className="page-card-container bg-white rounded shadow-sm animate-pulse">
      <div className="px-4 py-6 m-0 flex justify-between content-center items-center">
        <div className="h-8 w-1/3 bg-gray-300 rounded"></div>
        <div className="h-14 w-64 bg-gray-300 rounded"></div>
      </div>
      <div className="px-4 pb-6">
        <div className="w-full h-[400px] bg-gray-300 rounded"></div>
        <div className="mb-0 pt-4">
          <div className="h-6 w-1/4 bg-gray-300 rounded mb-2"></div>
          <div className="h-4 w-full bg-gray-300 rounded mb-4"></div>
          <div className="h-6 w-1/4 bg-gray-300 rounded mb-2"></div>
          <ul className="list-disc pl-6">
            {[...Array(3)].map((_, index) => (
              <li key={index} className="mb-2">
                <div className="h-4 w-3/4 bg-gray-300 rounded"></div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default StudentCourseSkeleton;
