import React from 'react';
import { Accordion } from 'flowbite-react';
import { Section } from '../../../types';
import { useMedia } from '../../../custom-hooks/useMedia';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

const LecturerCourseProgressView = ({ sections }: { sections: Section[] | undefined }) => {
  const { mediaId, courseId } = useParams();
  if (!sections) return null;

  const allMediaIds = sections.flatMap((section) => section.media.map(({ mediaId }) => mediaId));
  const { data: allMediaData } = useMedia(allMediaIds);
  const getSectionMedia = (section: Section) => {
    return allMediaData?.filter((mediaItem) => section.media.some(({ mediaId }) => mediaId === mediaItem.id)) || [];
  };
  return (
    <Accordion>
      {sections.map((section: Section) => {
        const sectionMedia = getSectionMedia(section);
        return (
          <Accordion.Panel key={section._id}>
            <Accordion.Title>
              <div>
                <p className="font-lato font-semibold text-base mb-0 capitalize">{section.title}</p>
              </div>
            </Accordion.Title>
            <Accordion.Content className="p-0">
              {sectionMedia.map((mediaItem, index) => (
                <div
                  key={index}
                  className={`border-b border-b-gray-300 py-4 ${
                    mediaItem.id === mediaId ? 'bg-accent2' : 'hover:bg-gray-200'
                  }`}
                >
                  <Link
                    key={mediaItem.id}
                    to={{
                      pathname: `/lecturer/courses/${courseId}/view/${mediaItem.id}`,
                    }}
                  >
                    <div className="ms-6">
                      <p className="mb-1 font-lato font-semibold text-sm capitalize text-gray-700">
                        {index + 1}. {mediaItem.title}
                      </p>
                      <p className="font-poppins font-medium text-xs text-start ms-3.5">{mediaItem.size} mins</p>
                    </div>
                  </Link>
                </div>
              ))}
            </Accordion.Content>
          </Accordion.Panel>
        );
      })}
    </Accordion>
  );
};

export default LecturerCourseProgressView;
