import React, { useEffect, useState } from 'react';
import { ArrowLeftCircle, ArrowRightCircle } from 'react-bootstrap-icons';

interface OrbitalCarouselItem {
  name: string;
  title: string;
  image: string;
  quote: string;
}

interface OrbitalCarouselProps {
  items: OrbitalCarouselItem[];
}
const HomeOrbitalCarousel: React.FC<OrbitalCarouselProps> = ({ items }) => {
  const [index, setIndex] = useState(0);

  const nextTestimonial = () => {
    setIndex((prev) => (prev + 1) % items.length);
  };

  const prevTestimonial = () => {
    setIndex((prev) => (prev - 1 + items.length) % items.length);
  };

  useEffect(() => {
    const sliderInterval = setInterval(nextTestimonial, 5000);

    return () => clearInterval(sliderInterval);
  }, [index]);

  return (
    <div className="flex flex-col items-center justify-center gap-4 space-y-6 w-full max-w-4xl xl:max-w-6xl mx-auto text-center">
      <div className="relative w-full h-64 flex items-center justify-center gap-6">
        {items.map((testimonial, i) => {
          const isActive = i === index;
          return (
            <div
              key={i}
              className={`transition-all duration-500 rounded-full overflow-hidden flex items-center justify-center ${
                isActive ? 'w-52 h-52 xl:w-64 xl:h-64 z-10' : 'w-20 h-20 xl:w-32 xl:h-32'
              } ${i % 2 === 0 ? 'self-start' : 'self-end'}`}
            >
              <img src={testimonial.image} alt={testimonial.name} className="w-full h-full object-cover rounded-full" />
            </div>
          );
        })}
      </div>
      <div className="flex items-center justify-between w-full px-6">
        <button onClick={prevTestimonial} className="p-2 rounded-full">
          <ArrowLeftCircle className="h-10 w-10 text-gray-800" />
        </button>
        <div className="text-center">
          <h3 className="text-lg font-semibold">{items[index].name}</h3>
          <p className="text-gray-500 mb-4">{items[index].title}</p>
          <p className="text-black px-4 text-xl">&quot;{items[index].quote}&quot;</p>
        </div>
        <button onClick={nextTestimonial} className="p-2 rounded-full">
          <ArrowRightCircle className="h-10 w-10 text-gray-800" />
        </button>
      </div>
    </div>
  );
};

export default HomeOrbitalCarousel;
