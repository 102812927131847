import React from 'react';

const LecturerSectionSkeleton: React.FC = () => {
  return (
    <div className="flex flex-col bg-white rounded shadow-sm page-card-container animate-pulse">
      <div className="flex flex-col md:flex-row content-center md:justify-between m-3 rounded bg-gray-300">
        <div className="p-8">
          <div className="h-6 w-48 bg-gray-400 rounded mb-6"></div>
          <div className="h-4 w-32 bg-gray-400 rounded"></div>
        </div>
        <div className="flex flex-row items-center justify-center p-8">
          <div className="h-12 w-36 bg-gray-400 rounded me-2"></div>
          <div className="h-12 w-36 bg-gray-400 rounded md:me-8"></div>
        </div>
      </div>
      <div className="flex-grow px-6 pb-6">
        <div className="h-10 w-56 bg-gray-400 rounded mb-4"></div>
        <div className="grid gap-8">
          <div>
            <div className="h-6 w-24 bg-gray-400 rounded mb-8 mt-2"></div>
            <div className="w-full border-b border-collapse table-auto border-x border-slate-100">
              <div className="h-24 bg-gray-300 rounded mb-2"></div>
              <div className="h-24 bg-gray-300 rounded mb-2"></div>
              <div className="h-24 bg-gray-300 rounded mb-2"></div>
              <div className="h-24 bg-gray-300 rounded mb-2"></div>
              <div className="h-24 bg-gray-300 rounded"></div>
            </div>
          </div>
          {/* <div>
            <div className="h-6 w-24 bg-gray-400 rounded mb-4"></div>
            <div className="h-40 bg-gray-300 rounded"></div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default LecturerSectionSkeleton;
