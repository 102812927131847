import React, { useState, useEffect, ChangeEvent } from 'react';
import axios from '../../utils/axios';
import { Link } from 'react-router-dom';
import { AxiosError } from 'axios';
import { Alert } from 'flowbite-react';
import { Course } from '../../types';
import { DEFAULT_ERROR_MESSAGE, imageUrls } from '../../utils/constants';
import { LoadingSpinner, Pagination, SearchBar } from '../../components';
import { currencyFormater } from '../../utils/utilMethods';
import { useSearch } from '../SearchContext';
import { CourseCardSkeleton } from './components';

const StudentCourseBrowser = () => {
  const [courses, setCourses] = useState<Course[]>([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [coursesPerPage] = useState(8);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');

  const getCourses = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`/courses`, {
        params: {
          page: currentPage,
          limit: coursesPerPage,
          title: searchQuery,
        },
      });
      setCourses(res.data.results);
      setTotalPages(Math.ceil(res.data.totalDocuments / coursesPerPage));
      setLoading(false);
    } catch (err) {
      if (err instanceof AxiosError) {
        setLoading(false);
        const errorMessage: string = err.response ? err.response.data.message : DEFAULT_ERROR_MESSAGE;
        setError(errorMessage);
      } else {
        setError(err.message);
      }
    }
  };

  useEffect(() => {
    getCourses();
  }, [currentPage, searchQuery]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    setCurrentPage(1);
  };

  return (
    <div className="pt-3 h-full">
      {error && (
        <Alert color="failure" className="mb-4">
          <p className="font-medium font-lato">{error}</p>
        </Alert>
      )}
      <div className="w-full md:w-1/2 mx-auto mb-8">
        <SearchBar onSearch={handleSearch} />
      </div>
      {loading ? (
        <CourseCardSkeleton />
      ) : courses.length >= 1 ? (
        <div className="flex flex-col h-full light-bg-grey">
          <div className="grid lg:grid-cols-12 gap-8 flex-grow">
            {courses.map((course: Course) => (
              <div key={`course-${course.id}-column`} className="lg:col-span-4 2xl:col-span-3 4k:col-span-4">
                <Link
                  to={{
                    pathname: `/student/course/${course.id}`,
                  }}
                  className="text-black text-decoration-none"
                >
                  <div className="bg-white rounded shadow-sm w-full hover:shadow-primary10 hover:shadow-md">
                    <img src={course.photoUrl} alt="" className="p-3 h-52 4k:h-[344px] rounded-lg w-full" />

                    <div className="px-3 mb-0">
                      <p className="font-lato font-bold text-base 4k:text-2xl mb-0.5 capitalize truncate">
                        {course.title}
                      </p>
                      <div className="flex justify-between items-center">
                        <p className="font-poppins font-medium text-sm text-slate-500 4k:text-lg capitalize truncate">
                          {course.creator?.firstName} {course.creator?.lastName}
                        </p>
                        <p className="font-lato font-bold text-base 4k:text-2xl">
                          {course.price && course.price > 0 ? currencyFormater(course.price) : 'Free'}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
          <div className="mx-auto mt-auto pt-10">
            <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
          </div>
        </div>
      ) : (
        <div className="flex justify-center content-center">
          <div>
            <img src={imageUrls.EmptyState} alt="Box" className="mx-auto block pb-3 cursor-pointer h-48" />
            <p className="text-center font-lato text-lg font-bold ">Sorry, there is no content here at the moment.</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default StudentCourseBrowser;
