import React from 'react';

const CourseCardSkeleton = () => {
  return (
    <div className="grid lg:grid-cols-12 gap-8 flex-grow">
      {Array(8)
        .fill(0)
        .map((_, index) => (
          <div
            key={index}
            className="h-64 col-span-6 lg:col-span-4 2xl:col-span-3 4k:col-span-4 bg-white shadow-sm rounded-lg animate-pulse"
          >
            <div className="w-full h-64 4k:h-[500px] rounded p-3">
              <div className="w-full h-48 4k:h-[450px] bg-gray-300"></div>
              <div className="pt-3 mb-0">
                <div className="h-4 bg-gray-300 rounded w-3/4 mb-2"></div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default CourseCardSkeleton;
