import { useQuery } from '@tanstack/react-query';
import { ProfileData } from '../types';
import React from 'react';
import axios from '../utils/axios';
import { imageUrls } from '../utils/constants';

export const useUserName = () => {
  const { data } = useQuery<ProfileData>({
    queryKey: ['profile'],
    queryFn: async () => {
      const res = await axios.get(`/users/me`);
      return res.data;
    },
  });
  return (
    <>
      {data?.title} {data?.lastName} {data?.firstName}
    </>
  );
};

export const useUserProfileImage = () => {
  const { data } = useQuery<ProfileData>({
    queryKey: ['profile'],
    queryFn: async () => {
      const res = await axios.get(`/users/me`);
      return res.data;
    },
  });
  const profilePhotoUrl =
    data?.profilePhotoUrl && data.profilePhotoUrl.trim() !== '' ? data.profilePhotoUrl : imageUrls.SilLogo;
  return profilePhotoUrl;
};

export const useUser = (id: string) => {
  return useQuery<ProfileData, Error>({
    queryKey: [id],
    queryFn: async () => {
      const res = await axios.get(`/users/${id}`);
      const data = res.data;
      return data;
    },
    enabled: !!id,
  });
};
