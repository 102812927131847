import React, { useEffect, useState } from 'react';
import { MediaViewer } from '../../components';
import { AxiosError } from 'axios';
import axios from '../../utils/axios';
import { useParams } from 'react-router-dom';
import { Media } from '../../types';
import { DEFAULT_ERROR_MESSAGE } from '../../utils/constants';

const LecturerMediaViewer = () => {
  const [error, setError] = useState('');
  const [mediaObject, setMediaObject] = useState<Media>();
  const [mediaLoading, setMediaLoading] = useState(false);
  const { mediaId } = useParams();

  const getMediaObject = async () => {
    try {
      setMediaLoading(true);
      const res = await axios.get(`media/${mediaId}`);
      setMediaObject(res.data);
      setMediaLoading(false);
    } catch (err) {
      setMediaLoading(false);
      if (err instanceof AxiosError) {
        const errorMessage: string = err.response ? err.response.data.message : DEFAULT_ERROR_MESSAGE;
        setError(errorMessage);
      } else {
        setError(err.message);
      }
    }
  };

  useEffect(() => {
    getMediaObject();
  }, []);

  return (
    <div className="bg-white rounded page-card-container">
      <div className="p-6 lg:h-5/6">
        {mediaLoading ? (
          <div className="w-full h-full animate-pulse bg-white flex flex-col py-4">
            <div className="flex-grow bg-gray-300 mb-4"></div>
            <div className="h-8 w-64 bg-gray-300"></div>
          </div>
        ) : (
          <MediaViewer mediaObject={mediaObject!} />
        )}
      </div>
    </div>
  );
};

export default LecturerMediaViewer;
