import React, { useEffect, useState } from 'react';
import { ArrowLeftCircle, ArrowRightCircle } from 'react-bootstrap-icons';

interface CarouselItem {
  src: string;
  description: string;
  author: string;
}

interface CarouselProps {
  items: CarouselItem[];
}

const HomeCarousel: React.FC<CarouselProps> = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const nextSlide = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % items.length);
  };

  const prevSlide = () => {
    setActiveIndex((prevIndex) => (prevIndex - 1 + items.length) % items.length);
  };

  useEffect(() => {
    const sliderInterval = setInterval(nextSlide, 5000);

    return () => clearInterval(sliderInterval);
  }, [activeIndex]);

  return (
    <div className="relative w-full overflow-hidden">
      <div className="flex items-center justify-center min-h-[640px] relative">
        {/* Previous Button */}
        <button
          className="absolute left-0 z-10 rounded-full w-12 h-12 border-none bg-white hover:bg-gray-100 transition"
          onClick={prevSlide}
          aria-label="Previous image"
        >
          <ArrowLeftCircle className="h-12 w-12 text-gray-400" />
        </button>

        {/* Carousel Container */}
        <div className="relative w-full flex flex-col justify-center items-center overflow-hidden">
          <div className="relative flex items-center justify-center w-full h-[640px]">
            {items.map((item, index) => {
              const position = (index - activeIndex + items.length) % items.length;
              const isCenter = position === 0;
              const isLeft = position === items.length - 1;
              const isRight = position === 1;

              return (
                <div
                  key={index}
                  className={`absolute transition-all duration-500 ease-in-out 
                    ${isCenter ? 'w-80 h-80 scale-100 z-10' : ''}
                    ${isLeft ? 'w-64 h-64 scale-75 -translate-x-72' : ''}
                    ${isRight ? 'w-64 h-64 scale-75 translate-x-72' : ''}`}
                >
                  <div
                    className={`relative rounded-lg overflow-hidden ${isCenter ? 'w-80 h-80' : ''} ${
                      isLeft ? 'w-64 h-64' : ''
                    } ${isRight ? 'w-64 h-64' : ''}`}
                  >
                    <img src={item.src || '/placeholder.svg'} alt="" className="object-cover w-full h-full" />
                  </div>
                </div>
              );
            })}
          </div>
          {/* Center Text */}
          <div className="absolute bottom-0 text-center w-[800px] transition-opacity duration-500 ease-in-out">
            <p className="font-poppins font-medium text-xl text-activeText mb-4">
              &quot;{items[activeIndex].description}&quot;
            </p>
            <p className="font-poppins font-normal italic text-lg text-activeText">{items[activeIndex].author}</p>
          </div>
        </div>

        {/* Next Button */}
        <button
          className="absolute right-0 z-10 rounded-full w-12 h-12 border-none bg-white hover:bg-gray-100 transition"
          onClick={nextSlide}
          aria-label="Next image"
        >
          <ArrowRightCircle className="h-12 w-12 text-gray-400" />
        </button>
      </div>
    </div>
  );
};

export default HomeCarousel;
