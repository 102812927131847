import { CustomFlowbiteTheme } from 'flowbite-react';

export const customModalTheme: CustomFlowbiteTheme['modal'] = {
  header: {
    close: {
      base: 'ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white',
      icon: 'h-5 w-5',
    },
  },
};
